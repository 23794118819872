// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

import map from 'lodash/map'
import isArray from 'lodash/isArray'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'
import OutLink from '@bodhi-project/components/lib/OutLink'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

import Timeline from 'antd/lib/timeline'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/timeline/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import NewImages from '../components/commons/NewImages'
import Schools from '../components/commons/Schools'

import seoHelper from '../methods/seoHelper'

import timeline from '../data/timeline.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Organisation',
  nakedPageSlug: 'organisation',
  pageAbstract:
    'We are a part of the Learning Wings family. Learning Wings is a global organisation devoted to high-quality education that meets the demands of parents, students and education providers the world over.',
}

const seoData = seoHelper(pageData)

const { Item } = Timeline
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "organisation/img1.jpg" }) {
      ...defaultImage
    }
    img2: file(relativePath: { eq: "organisation/img2.jpg" }) {
      ...defaultImage
    }
    img3: file(relativePath: { eq: "organisation/img3.jpg" }) {
      ...defaultImage
    }
    img4: file(relativePath: { eq: "organisation/img4.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
  },

  '& h1, h2': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <h1 className="mask-h3">Organisation</h1>
        <p className="mask-p">
          We are a part of the <i>Learning Wings</i> family.
        </p>
        <p>
          Learning Wings is a global organisation devoted to high-quality
          education that meets the demands of parents, students and education
          providers, the world over. It is also a leading provider of school
          management services and works closely with schools to raise student
          achievements. It's flagship schools - Cambridge International School -
          spread across North India is showing children the process of realizing
          their potential.
        </p>
        <p>
          <OutLink to="https://www.learningwings.org/">
            Visit the Learning Wings website
          </OutLink>
          .
        </p>
        <div className="mask-p">
          <NewImages
            data={props.data}
            lookup="img"
            columns={{ min: 2, max: 2 }}
          />
        </div>
        <h2>Brief Timeline</h2>
        <Timeline>
          {map(timeline, timelineItem => {
            const { text, time } = timelineItem
            return (
              <Item>
                <div>
                  <strong>{time}</strong>
                  <br />
                  {isArray(text) ? (
                    <ul style={{ fontSize: 'unset' }}>
                      {map(text, textItem => (
                        <li style={{ fontSize: 'unset' }}>{textItem}</li>
                      ))}
                    </ul>
                  ) : (
                    <Fragment>{text}</Fragment>
                  )}
                </div>
              </Item>
            )
          })}
        </Timeline>
        <h2 className="mask-h4">History</h2>
        <p>
          Learning Wings Education Systems was born in the summer of 2004, as a
          mission of a visionary entrepreneur, Mr. Ajay Bhatia. To join him in
          this endeavour were Dr. Brijesh Kumar, Ms. Deepa Dogra, Mr. Deepak
          Bhatia, all committed to a single pointed vision - to provide quality
          education to all strata of society at most reasonable and affordable
          cost.
        </p>
      </Col>
      <Col sm={24} md={8}>
        <Schools flexDirection="column" />
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
